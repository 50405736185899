export class Footer {
    constructor() {
        this.subMenu = document.querySelectorAll('.footer .menu-item-has-children');

        if (this.subMenu.length) {
            this.init();
        }
    }

    init() {
        this.subMenu.forEach((el) => {
            el.addEventListener('click', function (e) {
                if (e.target.href.includes('#')) {
                    e.preventDefault();
                    this.classList.toggle('active');

                }
            });
        });
    }
}
