import iframeResize from "@iframe-resizer/parent";

export class IframeCambridge {
    constructor() {
        this.iframe = document.querySelector('#icemdesk')

        if (!this.iframe) {
            return;
        }

        this.init();
    }

    init() {
        //faut une licence

        // iframeResize({log: false}, '#icemdesk');
        //
        // let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        // let screenWidth = 0;
        // if (iOS) {
        //     screenWidth = screen.width - 50;
        // } else {
        //     screenWidth = screen.width
        // }
        // iframeResize({
        //     maxHeight: 1 / 0,
        //     maxWidth: screenWidth,
        //     minHeight: 0,
        //     minWidth: 0,
        //     resizeFrom: "parent",
        //     scrolling: !1,
        //     sizeHeight: !0,
        //     sizeWidth: true,
        // }, '#icemdesk')
    }
}
