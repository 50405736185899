import {Autoplay, Navigation, Pagination} from "swiper/modules";
import Swiper from "swiper";

export class Slider {
    constructor()
    {
        this.slider = document.querySelectorAll('.swiper')

        if (this.slider.length > 0) {
            this.init();
        }
    }

    init()
    {
        this.slider.forEach((slider) => {
            const swiper = new Swiper(slider, {
                centeredSlides: !!slider.getAttribute('data-centeredSlides'),
                spaceBetween: slider.getAttribute('data-spaceBetween') ?? 24,
                slidesPerView: 1,
                modules: [Navigation, Pagination, Autoplay],
                navigation: {
                    nextEl: '.swiper-button-next' + slider.getAttribute('data-id'),
                    prevEl: '.swiper-button-prev' + slider.getAttribute('data-id'),
                },
                pagination: {
                    el: '.swiper-pagination' + slider.getAttribute('data-id'),
                    clickable: true,
                },
                breakpoints: {
                    768: {
                        slidesPerView: slider.getAttribute('data-slidePerView') ?? 1,
                    }
                }
            });

        if (slider.getAttribute('data-autoplay')) {
            console.log('oui')
            swiper.params.autoplay = {
                delay: 5000,
                duration: 5,
            }

            swiper.autoplay.start();
        }
        });
    }
}
