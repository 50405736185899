export class Woocommerce {
    constructor() {
        document.querySelectorAll('input[input-trigger-woo]').forEach((container) => {
            const trigger = container.getAttribute('input-trigger-woo');
            const triggers = JSON.parse(trigger);

            Object.keys(triggers).forEach((trigger) => {
                const trigger_endpoint = document.querySelector(`select[name=${trigger}]`);

                if (trigger_endpoint) {
                    container.addEventListener('change', (e) => {
                        trigger_endpoint.value = triggers[trigger];
                        trigger_endpoint.dispatchEvent(new CustomEvent('change', {bubbles: true }));
                    })
                }
            })
        })

        document.querySelectorAll('form .form-row input').forEach((input) => {
            if (input.type !== 'radio' && input.type !== 'checkbox') {
                // ajoute un listeneur quand le champ est focus
                input.addEventListener('focus', (e) => {
                    const container = e.target.closest('.form-row');
                    container.classList.add('active');
                })

                // check si le champ est vide quand il perd le focus
                input.addEventListener('blur', (e) => {
                    const container = e.target.closest('.form-row');
                    // check initiale si le champ est vide et que ce n'est pas un input radio ni checkbox
                    if (input.value === '' && input.type !== 'radio' && input.type !== 'checkbox') {
                        const container = input.closest('.form-row');
                        container.classList.remove('active');
                    }
                })

                // check si l'input est différent d'un input radio ou checkbox et ajoute la classe default
                const container = input.closest('.form-row');
                container.classList.add('custom-row');
            }

            if (input.value !== '' && input.type !== 'radio' && input.type !== 'checkbox') {
                const container = input.closest('.form-row');
                container.classList.add('active');
            }
        })
    }
}
